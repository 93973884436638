import React, { useContext, useEffect } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useLocation } from "@reach/router"
import { Container, Tab, Nav, Row, Col, ListGroup } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import GlobalContext from "../contexts/GlobalContext"

import { slugCat, slugSubCat } from "../utils/slugs"
import { Box } from "../components/Core"
import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Text } from "../components/Core"
import renderSlices from "../components/Slices"
import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg2.jpg"

const Product = ({ data }) => {
  const { pathname } = useLocation()
  const gContext = useContext(GlobalContext)

  const product = data.prismicProduct

  const { body: slices = [] } = product.data

  const catParent = product.data.category.document
  const catSubParent = product.data.sub_category.document

  const { btn_back, btn_home, btn_request_sample } = data.prismicCommon?.data

  useEffect(() => {
    if (gContext.currentLang !== product.lang) {
      gContext.setCurrentLang(product.lang)
    }
  }, [])

  return (
    <>
      <GatsbySeo
        title={product.data.meta_title?.text || product.data.title?.text}
        description={product.data.meta_description?.text}
      />
      <PageWrapper>
        <Hero
          bgImg={
            product.data.cover_image.url ||
            (catSubParent && catSubParent.data.cover_image.url) ||
            (catParent && catParent.data.cover_image.url) ||
            bgImg
          }
        >
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {product.data.title && (
                  <Title variant="hero" color="light" mb={3}>
                    {product.data.title.text}
                  </Title>
                )}

                {(catParent || catSubParent) && (
                  <>
                    <div className="d-flex my-3 justify-content-center text-white">
                      {catParent && (
                        <div>
                          {">>"}
                          <Link
                            to={slugCat(catParent.uid, gContext.currentLang)}
                            className="text-white font-weight-bold"
                          >
                            {catParent.data.title.text}
                          </Link>
                        </div>
                      )}
                      {catSubParent && (
                        <div className="ml-2">
                          {">>"}
                          <Link
                            to={slugSubCat(
                              catSubParent.uid,
                              catParent.uid,
                              gContext.currentLang
                            )}
                            className="text-white font-weight-bold"
                          >
                            {catSubParent.data.title.text}
                          </Link>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Hero>

        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9">
                <Tab.Container defaultActiveKey="info">
                  <Row>
                    <Col md={3} className="mb-5 mb-lg-0">
                      {catSubParent && (
                        <>
                          <div className="d-flex my-3 justify-content-start">
                            <div className="text-white font-weight-bold ml-2">
                              <Link
                                to={slugSubCat(
                                  catSubParent.uid,
                                  catParent.uid,
                                  gContext.currentLang
                                )}
                                className="text-dark font-weight-bold"
                              >
                                {"<<"}{" "}
                                {btn_back && btn_back.text.length > 0
                                  ? btn_back.text
                                  : "Back"}
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                      <ListGroup
                        css={`
                          .list-group-flush:first-child,
                          .list-group-item:first-child {
                            border-top: 1px solid rgba(24, 59, 86, 0.115);
                          }
                          .active.nav-link {
                            color: white !important;
                          }
                        `}
                      >
                        <ListGroup.Item as={Nav.Link} eventKey="info">
                          Overview
                        </ListGroup.Item>

                        {product.data.pdf.url && (
                          <ListGroup.Item
                            as="a"
                            href={product.data.pdf.url}
                            target="_blank"
                            className="disable-active"
                            eventKey="info"
                          >
                            PDF
                          </ListGroup.Item>
                        )}

                        <ListGroup.Item
                          as={AnchorLink}
                          to={`${pathname}#support`}
                          stripHash
                        >
                          {btn_request_sample &&
                          btn_request_sample.text.length > 0
                            ? btn_request_sample.text
                            : "Request Sample"}
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={9}>
                      {(catParent || catSubParent) && (
                        <Box mb={4}>
                          <div className="d-flex my-3 justify-content-start text-dark">
                            {catSubParent && (
                              <div className="ml-2">
                                {" >>"}
                                <Link
                                  to={slugSubCat(
                                    catSubParent.uid,
                                    catParent.uid,
                                    gContext.currentLang
                                  )}
                                  className="text-primary font-weight-bold"
                                >
                                  {catSubParent.data.title.text}
                                </Link>
                              </div>
                            )}
                            <div className="text-dark font-weight-bold ml-2">
                              <div>
                                {" >>"}
                                {product.data.title.text}
                              </div>
                            </div>
                          </div>
                        </Box>
                      )}
                      <Tab.Content>
                        <Tab.Pane eventKey="info">
                          {slices.map(({ slice_type, primary, items }, i) =>
                            renderSlices(slice_type, primary, items, `key_${i}`)
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default Product

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismicCommon(lang: { eq: $lang }) {
      lang
      data {
        btn_back {
          text
        }
        btn_home {
          text
        }
        btn_request_sample {
          text
        }
      }
    }

    prismicProduct(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }

        title {
          text
        }
        cover_image {
          url
        }
        type
        pdf {
          url
        }
        category {
          uid
          document {
            ... on PrismicCategory {
              data {
                title {
                  text
                }
                cover_image {
                  url
                }
              }
              uid
            }
          }
        }
        sub_category {
          uid
          document {
            ... on PrismicSubCategory {
              uid
              data {
                title {
                  text
                }
                cover_image {
                  url
                }
              }
            }
          }
        }
        body {
          ... on PrismicProductBodySection {
            slice_type
            items {
              div {
                raw
              }
            }
            primary {
              title1 {
                text
              }
            }
          }
          ... on PrismicProductBodyImageGallery {
            slice_type
            primary {
              title1 {
                text
              }
            }
            items {
              caption {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicProductBodyTable {
            slice_type
            primary {
              total_cells
              title1 {
                text
              }
            }
            items {
              cell_1 {
                raw
              }
              cell_2 {
                raw
              }
              cell_3 {
                raw
              }
              cell_4 {
                raw
              }
              cell_5 {
                raw
              }
              cell_6 {
                raw
              }
              cell_7 {
                raw
              }
              cell_8 {
                raw
              }
              cell_9 {
                raw
              }
              cell_10 {
                raw
              }
              cell_11 {
                raw
              }
              cell_12 {
                raw
              }
            }
          }
        }
      }
    }
  }
`
